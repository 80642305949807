export default [
    {
        category: 'suggested',
        name: 'Faces...',
    },
    {
        category: 'smileys_people',
        name: 'Faces...',
    },
    {
        category: 'animals_nature',
        name: 'Faces...',
    },
    {
        category: 'food_drink',
        name: 'Faces...',
    },
    {
        category: 'travel_places',
        name: 'Faces...',
    },
    {
        category: 'activities',
        name: 'Faces...',
    },
    {
        category: 'objects',
        name: 'Faces...',
    },
    // {
    //     category: 'symbols',
    //     name: 'Faces...',
    // },
];
