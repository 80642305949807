import React from 'react';

import { PlayerIconsProps } from '../types';

function Player(props: PlayerIconsProps) {
    const { variant, size = 24, active = true } = props;

    const fill = active ? '#7B57C8' : '#A5ABD7';

    switch (variant) {
        case 'list-visible': {
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.75 6H3" stroke="#7B57C8" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M10 10H3" stroke="#7B57C8" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M8 14H3" stroke="#7B57C8" strokeWidth="1.5" strokeLinecap="round" />
                    <path
                        d="M20.8889 13.6769C21.3827 13.962 21.3827 14.6748 20.8889 14.9599L13.1111 19.4504C12.6173 19.7356 12 19.3792 12 18.8089L12 9.82792C12 9.2577 12.6173 8.90131 13.1111 9.18642L20.8889 13.6769Z"
                        fill={fill}
                    />
                </svg>
            );
        }
        case 'random': {
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.0422 4.33055C20.2445 5.04655 20.3457 5.79627 20.3457 6.5797C20.3457 7.73254 20.1266 8.81236 19.6886 9.81917C19.2505 10.826 18.6587 11.7021 17.9132 12.4476C17.1677 13.1931 16.2915 13.7849 15.2847 14.223C14.2779 14.6611 13.1981 14.8801 12.0453 14.8801C10.8924 14.8801 9.8126 14.6611 8.80579 14.223C7.79898 13.7849 6.92282 13.1931 6.17732 12.4476C5.43182 11.7021 4.84003 10.826 4.40195 9.81917C3.96388 8.81236 3.74484 7.73254 3.74484 6.5797C3.74484 6.52865 3.68311 6.50309 3.64701 6.53918L2.72884 7.45736C2.49929 7.68691 2.12814 7.69016 1.89461 7.46468L1.65325 7.23164C1.28227 6.87345 1.27707 6.28066 1.64171 5.91602L4.01496 3.54277C4.37513 3.1826 4.95908 3.1826 5.31925 3.54277L7.6925 5.91602C8.05714 6.28066 8.05194 6.87345 7.68096 7.23164L7.4396 7.46468C7.20607 7.69016 6.83492 7.68691 6.60537 7.45736L5.6872 6.53918C5.6511 6.50309 5.58938 6.52865 5.58938 6.5797C5.58938 8.37813 6.21575 9.90372 7.4685 11.1565C8.72125 12.4092 10.2468 13.0356 12.0453 13.0356C13.8437 13.0356 15.3693 12.4092 16.622 11.1565C17.8748 9.90372 18.5011 8.37813 18.5011 6.5797C18.5011 6.03497 18.4437 5.51527 18.3287 5.0206C18.2286 4.58952 18.3849 4.12523 18.764 3.89694L18.787 3.88308C19.2646 3.59554 19.8906 3.79413 20.0422 4.33055Z"
                        fill={fill}
                    />
                    <path
                        d="M3.95778 19.4507C3.75547 18.7347 3.65431 17.985 3.65431 17.2015C3.65431 16.0487 3.87335 14.9689 4.31143 13.9621C4.74951 12.9553 5.3413 12.0791 6.0868 11.3336C6.8323 10.5881 7.70846 9.99632 8.71527 9.55824C9.72208 9.12016 10.8019 8.90112 11.9547 8.90112C13.1076 8.90112 14.1874 9.12016 15.1942 9.55824C16.201 9.99632 17.0772 10.5881 17.8227 11.3336C18.5682 12.0791 19.16 12.9553 19.598 13.9621C20.0361 14.9689 20.2552 16.0487 20.2552 17.2015C20.2552 17.2526 20.3169 17.2782 20.353 17.2421L21.2712 16.3239C21.5007 16.0943 21.8719 16.0911 22.1054 16.3166L22.3467 16.5496C22.7177 16.9078 22.7229 17.5006 22.3583 17.8652L19.985 20.2385C19.6249 20.5987 19.0409 20.5987 18.6808 20.2385L16.3075 17.8652C15.9429 17.5006 15.9481 16.9078 16.319 16.5496L16.5604 16.3166C16.7939 16.0911 17.1651 16.0943 17.3946 16.3239L18.3128 17.2421C18.3489 17.2782 18.4106 17.2526 18.4106 17.2015C18.4106 15.4031 17.7843 13.8775 16.5315 12.6248C15.2788 11.372 13.7532 10.7457 11.9547 10.7457C10.1563 10.7457 8.63072 11.372 7.37798 12.6248C6.12523 13.8775 5.49885 15.4031 5.49885 17.2015C5.49885 17.7463 5.55632 18.266 5.67125 18.7606C5.77141 19.1917 5.61514 19.656 5.23599 19.8843L5.21298 19.8982C4.73545 20.1857 4.10935 19.9871 3.95778 19.4507Z"
                        fill={fill}
                    />
                </svg>
            );
        }
        case 'list-repeat': {
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 22C10.75 22 9.57917 21.7625 8.4875 21.2875C7.39583 20.8125 6.44583 20.1708 5.6375 19.3625C4.82917 18.5542 4.1875 17.6042 3.7125 16.5125C3.36615 15.7165 3.14607 14.8784 3.05226 13.9982C2.99374 13.449 3.44772 13 4 13C4.55228 13 4.99193 13.4498 5.06252 13.9975C5.25878 15.5205 5.91711 16.8421 7.0375 17.9625C8.39583 19.3208 10.05 20 12 20C13.95 20 15.6042 19.3208 16.9625 17.9625C18.3208 16.6042 19 14.95 19 13C19 11.05 18.3208 9.39583 16.9625 8.0375C15.6042 6.67917 13.95 6 12 6C11.9446 6 11.9169 6.06693 11.9561 6.10607L12.9516 7.10163C13.2005 7.35052 13.204 7.75295 12.9596 8.00617L12.7069 8.26787C12.3185 8.67012 11.6758 8.67576 11.2804 8.28038L8.70711 5.70711C8.31658 5.31658 8.31658 4.68342 8.70711 4.29289L11.2804 1.71962C11.6758 1.32424 12.3185 1.32988 12.7069 1.73213L12.9596 1.99383C13.204 2.24705 13.2005 2.64948 12.9516 2.89837L11.9561 3.89393C11.9169 3.93308 11.9446 4 12 4C13.25 4 14.4208 4.2375 15.5125 4.7125C16.6042 5.1875 17.5542 5.82917 18.3625 6.6375C19.1708 7.44583 19.8125 8.39583 20.2875 9.4875C20.7625 10.5792 21 11.75 21 13C21 14.25 20.7625 15.4208 20.2875 16.5125C19.8125 17.6042 19.1708 18.5542 18.3625 19.3625C17.5542 20.1708 16.6042 20.8125 15.5125 21.2875C14.4208 21.7625 13.25 22 12 22Z"
                        fill={fill}
                    />
                    <path
                        d="M4.62184 7.70487C3.98574 8.6547 3.54063 9.71116 3.28651 10.8743C3.18552 11.3364 3.52854 11.7619 3.99884 11.8134L4.12815 11.8275C4.67181 11.887 5.15205 11.4881 5.28815 10.9584C5.48 10.2117 5.80626 9.49443 6.26691 8.80658C6.61875 8.28121 7.01631 7.82181 7.45958 7.42838C7.91032 7.02832 8.01509 6.32373 7.60808 5.87927C7.28497 5.52642 6.74711 5.46234 6.37837 5.76719C5.693 6.3338 5.10749 6.9797 4.62184 7.70487Z"
                        fill={fill}
                    />
                    <path d="M8 10.543H16" stroke="#7B57C8" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M8 13.043H16" stroke="#7B57C8" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M8 15.543H16" stroke="#7B57C8" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
            );
        }
        case 'prev': {
            return (
                <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 12.9654C5.97222 12.5164 5.97222 11.3938 6.75 10.9447L19 3.87218C19.7778 3.42313 20.75 3.98444 20.75 4.88254V19.0276C20.75 19.9257 19.7778 20.487 19 20.038L6.75 12.9654Z" />
                    <path d="M4 19.4551L4 4.45508" stroke="#7B57C8" strokeWidth="3" strokeLinecap="round" />
                </svg>
            );
        }
        case 'next': {
            return (
                <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.25 11.0346C18.0278 11.4836 18.0278 12.6062 17.25 13.0553L5 20.1278C4.22222 20.5769 3.25 20.0156 3.25 19.1175L3.25 4.97238C3.25 4.07428 4.22222 3.51297 5 3.96202L17.25 11.0346Z" />
                    <path d="M20 4.54492V19.5449" stroke="#7B57C8" strokeWidth="3" strokeLinecap="round" />
                </svg>
            );
        }
        case 'repeat': {
            return (
                <svg
                    width="24.000000"
                    height="24.000000"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <desc>Created with Pixso.</desc>
                    <defs />
                    <path
                        id="repeat123"
                        d="M12 22C10.75 22 9.5791 21.7617 8.48755 21.2871C7.39575 20.8125 6.4458 20.1699 5.63745 19.3633C4.8291 18.5547 4.1875 17.6035 3.7124 16.5117C3.36621 15.7168 3.146 14.8789 3.05225 13.998C2.99365 13.4492 3.44775 13 4 13C4.55225 13 4.99194 13.4492 5.0625 13.998C5.25879 15.5195 5.91699 16.8418 7.0376 17.9629C8.39575 19.3203 10.05 20 12 20C13.95 20 15.6042 19.3203 16.9624 17.9629C18.3208 16.6035 19 14.9492 19 13C19 11.0508 18.3208 9.39648 16.9624 8.03711C15.6042 6.67969 13.95 6 12 6C11.9446 6 11.917 6.06641 11.9561 6.10547L12.9517 7.10156C13.2004 7.34961 13.2041 7.75391 12.9595 8.00586L12.7068 8.26758C12.3186 8.66992 11.6758 8.67578 11.2803 8.28125L8.70703 5.70703C8.31665 5.31641 8.31665 4.68359 8.70703 4.29297L11.2803 1.71875C11.6758 1.32422 12.3186 1.33008 12.7068 1.73242L12.7175 1.74219C13.0964 2.13477 13.0908 2.75977 12.7051 3.14453L11.9561 3.89453C11.917 3.93359 11.9446 4 12 4C13.25 4 14.4209 4.23828 15.5125 4.71289C16.6042 5.1875 17.5542 5.83008 18.3625 6.63672C19.1709 7.44531 19.8125 8.39648 20.2876 9.48828C20.7625 10.5801 21 11.75 21 13C21 14.25 20.7625 15.4199 20.2876 16.5117C19.8125 17.6035 19.1709 18.5547 18.3625 19.3633C17.5542 20.1699 16.6042 20.8125 15.5125 21.2871C14.4209 21.7617 13.25 22 12 22Z"
                        fill={fill}
                        fillOpacity="1.000000"
                        fillRule="evenodd"
                    />
                    <path
                        id="repeat"
                        d="M3.28687 10.875C3.54077 9.71094 3.98608 8.6543 4.62207 7.70508C5.10767 6.98047 5.69336 6.33398 6.37866 5.76758C6.74731 5.46289 7.28516 5.52734 7.6084 5.87891C8.01538 6.32422 7.91064 7.0293 7.45972 7.42773C7.0166 7.82227 6.6189 8.28125 6.26709 8.80664C5.80981 9.49023 5.48511 10.2012 5.29272 10.9414C5.15283 11.4805 4.66455 11.8867 4.11157 11.8262L3.99902 11.8125C3.52881 11.7617 3.18579 11.3359 3.28687 10.875Z"
                        fill={fill}
                        fillOpacity="1.000000"
                        fillRule="evenodd"
                    />
                </svg>
            );
        }
        case 'play-outline': {
            return (
                <svg
                    width={size}
                    height={size}
                    viewBox="0 0 28.0869 28.0869"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <desc>Created with Pixso.</desc>
                    <defs />
                    <path
                        id="Vector"
                        d="M14.0435 0C6.28442 0 0 6.28418 0 14.0439C0 21.8027 6.28442 28.0869 14.0435 28.0869C21.8025 28.0869 28.0869 21.8027 28.0869 14.0439C28.0869 6.28418 21.8025 0 14.0435 0ZM10.5327 18.5898L10.5327 9.49707C10.5327 8.8125 11.3049 8.39062 11.8843 8.75977L19.0288 13.3066C19.573 13.6572 19.573 14.4473 19.0288 14.7803L11.8843 19.3271C11.3049 19.6963 10.5327 19.2744 10.5327 18.5898Z"
                        fillOpacity="1.000000"
                        fillRule="nonzero"
                    />
                </svg>
            );
        }
        case 'pause-outline': {
            return (
                <svg width={size} height={size} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <desc>Created with Pixso.</desc>
                    <defs />
                    <path
                        id="Vector"
                        d="M19 0C8.50244 0 0 8.5025 0 19C0 29.4975 8.50244 38 19 38C29.4976 38 38 29.4975 38 19C38 8.5025 29.4976 0 19 0ZM14.8438 26.125C13.8701 26.125 13.0625 25.3175 13.0625 24.3438L13.0625 13.6562C13.0625 12.6825 13.8701 11.875 14.8438 11.875C15.8174 11.875 16.625 12.6825 16.625 13.6562L16.625 24.3438C16.625 25.3175 15.8174 26.125 14.8438 26.125ZM23.1562 26.125C22.1826 26.125 21.375 25.3175 21.375 24.3438L21.375 13.6562C21.375 12.6825 22.1826 11.875 23.1562 11.875C24.1299 11.875 24.9375 12.6825 24.9375 13.6562L24.9375 24.3438C24.9375 25.3175 24.1299 26.125 23.1562 26.125Z"
                        fillOpacity="1.000000"
                        fillRule="nonzero"
                    />
                </svg>
            );
        }
        case 'play': {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                >
                    <path d="M405.2 232.9L126.8 67.2c-3.4-2-6.9-3.2-10.9-3.2-10.9 0-19.8 9-19.8 20H96v344h.1c0 11 8.9 20 19.8 20 4.1 0 7.5-1.4 11.2-3.4l278.1-165.5c6.6-5.5 10.8-13.8 10.8-23.1s-4.2-17.5-10.8-23.1z" />
                </svg>
            );
        }
        case 'full': {
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.50164 21C3.94845 21 3.5 20.5515 3.5 19.9984V16C3.5 15.4477 3.94772 15 4.5 15V15C5.05228 15 5.5 15.4477 5.5 16V17.6L9.7918 13.2224C10.1818 12.8246 10.8214 12.8214 11.2153 13.2153L11.2847 13.2847C11.6786 13.6786 11.6754 14.3182 11.2776 14.7082L6.9 19H8.5C9.05228 19 9.5 19.4477 9.5 20V20C9.5 20.5523 9.05228 21 8.5 21H4.50164ZM15.2082 10.7776C14.8182 11.1754 14.1786 11.1786 13.7847 10.7847L13.7153 10.7153C13.3214 10.3214 13.3246 9.68179 13.7224 9.2918L18.1 5H16.5C15.9477 5 15.5 4.55228 15.5 4V4C15.5 3.44772 15.9477 3 16.5 3H20.4984C21.0515 3 21.5 3.44845 21.5 4.00164V8C21.5 8.55228 21.0523 9 20.5 9V9C19.9477 9 19.5 8.55228 19.5 8V6.4L15.2082 10.7776Z" />
                </svg>
            );
        }
        case 'no-full': {
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8038 3.80425L20.7349 3.73449C20.3432 3.33839 19.7035 3.33799 19.3113 3.73359L14.9952 8.0872L15.0041 6.48723C15.0072 5.93495 14.562 5.48474 14.0097 5.48166C13.4574 5.47858 13.0072 5.92379 13.0041 6.47606L12.9818 10.4744C12.9787 11.0275 13.4246 11.4785 13.9778 11.4816L17.9761 11.5039C18.5284 11.507 18.9786 11.0618 18.9817 10.5095C18.9848 9.95722 18.5396 9.50701 17.9873 9.50393L16.3873 9.495L20.7888 5.2277C21.1888 4.83994 21.1955 4.20035 20.8038 3.80425Z" />
                    <path d="M4.25979 20.3046L4.32958 20.3736C4.72583 20.7651 5.36542 20.7581 5.75302 20.358L10.0185 15.9548L10.0281 17.5547C10.0314 18.107 10.4818 18.552 11.0341 18.5487C11.5864 18.5454 12.0314 18.095 12.0281 17.5428L12.0041 13.5445C12.0008 12.9913 11.5497 12.5455 10.9965 12.5489L6.99823 12.5728C6.44595 12.5761 6.00093 13.0265 6.00423 13.5788C6.00754 14.131 6.45793 14.5761 7.0102 14.5728L8.61018 14.5632L4.25831 18.8811C3.86287 19.2735 3.86353 19.9131 4.25979 20.3046Z" />
                </svg>
            );
        }
        case 'mute': {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
                    <path d="M21.707 20.293l-2.023-2.023A9.566 9.566 0 0021.999 12c0-4.091-2.472-7.453-5.999-9v2c2.387 1.386 3.999 4.047 3.999 7a8.113 8.113 0 01-1.672 4.913l-1.285-1.285C17.644 14.536 18 13.19 18 12c0-1.771-.775-3.9-2-5v7.586l-2-2V4a1 1 0 00-1.554-.832L7.727 6.313l-4.02-4.02-1.414 1.414 18 18 1.414-1.414zM12 5.868v4.718L9.169 7.755 12 5.868zM4 17h2.697l5.748 3.832a1.004 1.004 0 001.027.05A1 1 0 0014 20v-1.879l-2-2v2.011l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 00-.382-.116C7.059 15.016 7.032 15 7 15H4V9h.879L3.102 7.223A1.995 1.995 0 002 9v6c0 1.103.897 2 2 2z" />
                </svg>
            );
        }
        case 'unmute': {
            return (
                <svg width={size} height={size} viewBox="0 0 18 17.5" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <desc>Created with Pixso.</desc>
                    <defs />
                    <path
                        id="volume_up"
                        d="M11 17.5L11 15.4492C12.5 15.0176 13.7085 14.1836 14.625 12.9492C15.5415 11.7168 16 10.3164 16 8.75C16 7.18359 15.5415 5.7832 14.625 4.55078C13.7085 3.31641 12.5 2.48242 11 2.05078L11 0C13.0669 0.466797 14.75 1.51172 16.0498 3.13672C17.3501 4.76172 18 6.63281 18 8.75C18 10.8672 17.3501 12.7383 16.0498 14.3633C14.75 15.9883 13.0669 17.0332 11 17.5ZM0 11.7754L0 5.77539L4 5.77539L9 0.775391L9 16.7754L4 11.7754L0 11.7754ZM11 12.7754L11 4.72461C11.7832 5.0918 12.396 5.64258 12.8374 6.375C13.2793 7.10742 13.5 7.9082 13.5 8.77539C13.5 9.625 13.2793 10.4121 12.8374 11.1367C12.396 11.8633 11.7832 12.4082 11 12.7754ZM7 5.625L4.8501 7.77539L2 7.77539L2 9.77539L4.8501 9.77539L7 11.9258L7 5.625Z"
                        fillOpacity="1.000000"
                        fillRule="nonzero"
                    />
                </svg>
            );
        }
        case 'pause': {
            return (
                <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M272 63.1h-32c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48l32 1.8c26.51 0 48-21.49 48-48V112c0-26.51-21.5-48.9-48-48.9zm-192 0H48c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448h32c26.51 0 48-21.49 48-48V112c0-26.51-21.5-48.9-48-48.9z" />
                </svg>
            );
        }
        case 'stop': {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M6 5h12a1 1 0 011 1v12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1z" />
                </svg>
            );
        }
        default:
            return null;
    }
}

export default Player;
